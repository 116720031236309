<template>
  <div>
    <div class="form--group">
      <div class="left--col">
        <div class="d-flex align-center justify-center">
          <label for="search-place-field">
            <span>{{ $t('addListing.pin.title') }}</span>
            <span class="required">*</span>
          </label>
          <PopOverListingForm
            :content="
              listingType === 'R' ? $t('addListing.pin.message') : $t('addListing.pin.messageS')
            "
          />
        </div>
      </div>
      <div class="right--col">
        <search-places class="input--margin" />
      </div>
    </div>
    <div style="position: relative">
      <section-loading :show="loadingMap" />
      <div class="map--container" id="propertyMap"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LodashMixin from '@/mixins/lodash';
import PopOverListingForm from '@/components/listing-form/pop-over-listing-form';
import SectionLoading from '@/components/content-loading/section-loading';

const SearchPlaces = () => import('@/components/listing-form/sect1/map/search-places.vue');
export default {
  name: 'map-section',
  components: { SearchPlaces, PopOverListingForm, SectionLoading },
  mixins: [LodashMixin],
  data() {
    return {
      icon: {
        url: '/img/map_pin.png', // url
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(50, 50), // scaled size
        // eslint-disable-next-line no-undef
        origin: new google.maps.Point(0, 0), // origin
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(25, 50), // anchor
      },
      map: null,
      marker: null,
      detailZoom: 15,
      overallZoom: 10,
      mapCreated: false,
      allowGeocode: true,
    };
  },
  beforeCreate() {
    this.latitude = -6.1753924;
    this.longitude = 106.8249641;
  },
  created() {
    this.debouncedLatLongByGeocode = this.debounce(this.getLatLongByGeocode, 500);
    if (this.form === 'edit') {
      this.allowGeocode = false;
    }
  },
  async mounted() {
    console.log('mounted map');
    if (this.form === 'add') {
      this.initMap();
    } else {
      this.setLoading(true);
      await this.$store.dispatch('v2/listingForm/fetchBaseData');
      this.$store.dispatch('v2/listingForm/sect1/openAllHiddenForms');
      this.setLoading(false);
      this.$nextTick(() => {
        this.initMap();
      });
    }
  },
  computed: {
    ...mapState({
      form: (state) => state.v2.listingForm.form,
      province: (state) => state.regional.province,
      city: (state) => state.regional.city,
      loadingMap: (state) => state.v2.listingForm.sect1.location.loadingMap,
      loadPropertyDataFromList: (state) =>
        state.v2.listingForm.sect1.location.loadPropertyDataFromList,
      fetchingEditListingData: (state) => state.v2.listingForm.fetchingEditListingData,
      listingType: (state) => state.v2.listingForm.listingType,
    }),
    geocodeQuery() {
      const city = this.city ? this.city.name : '';
      const province = this.province ? this.province.name : '';
      return `${city} ${province}`;
    },
    latitude: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.latitude;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/location/CHANGE_LATITUDE', val);
      },
    },
    longitude: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.longitude;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/location/CHANGE_LONGITUDE', val);
      },
    },
  },
  watch: {
    // fetchingEditListingData(value){
    //     if(!value){
    //         if(this.form==='edit'){
    //             this.initMap();
    //         }
    //     }
    // },
    geocodeQuery(value) {
      if (!this.loadPropertyDataFromList && this.allowGeocode) {
        this.debouncedLatLongByGeocode(value);
      }
    },
    latitude(val, oldVal) {
      if (oldVal !== '' && oldVal != null) {
        this.changeLocationByLatLong(this.latitude, this.longitude);
      }
    },
    longitude(val, oldVal) {
      if (oldVal !== '' && oldVal != null) {
        this.changeLocationByLatLong(this.latitude, this.longitude);
      }
    },
  },
  methods: {
    setLoading(value) {
      this.$store.commit('v2/listingForm/SET_LOADING', value);
    },
    placeDetailFound() {
      this.map.setZoom(this.detailZoom);
    },
    changeLocationByLatLong(lat, lng) {
      if (this.mapCreated) {
        // eslint-disable-next-line no-undef
        const latlng = new google.maps.LatLng(lat, lng);
        this.marker.setPosition(latlng);
        this.map.panTo(latlng);
      }
    },
    getLatLongByGeocode(searchQuery) {
      // eslint-disable-next-line no-undef
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address: searchQuery,
        },
        (results, status) => {
          // eslint-disable-next-line no-undef
          if (status === google.maps.GeocoderStatus.OK) {
            const resultJson = results[0].geometry.location.toJSON(),
              lng = resultJson.lng;
            this.latitude = resultJson.lat;
            this.longitude = lng;
            this.map.setZoom(this.overallZoom);
          }
        },
      );
    },
    getFitBound() {
      // eslint-disable-next-line no-undef
      const bound = new google.maps.LatLngBounds();
      bound.extend(this.marker.position);
      return bound;
    },
    getUserLocation() {
      return new Promise((resolve) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              (this.latitude = position.coords.latitude),
                (this.longitude = position.coords.longitude);
              resolve();
            },
            function () {
              resolve();
            },
          );
        } else {
          resolve();
        }
      });
    },
    initMarker() {
      // eslint-disable-next-line no-undef
      this.marker = new google.maps.Marker({
        position: {
          lat: this.latitude,
          lng: this.longitude,
        },
        icon: this.icon,
        map: this.map,
        draggable: true,
        // eslint-disable-next-line no-undef
        animation: google.maps.Animation.DROP,
      });
      this.marker.addListener('dragend', (event) => {
        const self = this;
        this.allowGeocode = false;
        this.latitude = event.latLng.lat();
        this.longitude = event.latLng.lng();
        // eslint-disable-next-line no-undef
        google.maps.event.addListenerOnce(this.map, 'bounds_changed', function () {
          if (this.getZoom() > self.detailZoom) {
            this.setZoom(self.detailZoom);
          }
        });
        this.map.fitBounds(this.getFitBound());
      });
    },
    async initMap() {
      let zoom = 5;
      if (this.form === 'add') {
        await this.getUserLocation();
      } else {
        zoom = this.detailZoom;
      }
      const options = {
        center: { lat: this.latitude, lng: this.longitude },
        zoom: zoom,
        zoomControl: true,
        zoomControlOptions: {
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        streetViewControl: false,
        maxZoom: 18,
      };
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('propertyMap'), options);
      this.mapCreated = true;
      this.initMarker();
    },
  },
};
</script>

<style scoped></style>
